import { NEW_PROJECT_PAGE, PROJECTS_PAGE } from '@/router/portfolio.names';
import flavorValues from '@/helpers/app-flavor';

const portfolio = () => import('@/pages/portfolio/portfolio.vue');
const project = () => import('@/pages/portfolio/project.vue');
const newProject = () => import('@/pages/portfolio/newProject.vue');
const editProject = () => import('@/pages/portfolio/editProject.vue');

const portfolioRoutes = [
  {
    path: '/portfolio',
    name: PROJECTS_PAGE,
    component: portfolio,
    meta: { hideFooter: true, matomoUrl: '/portfolio/', title: 'Portfolio' },
  },
  {
    path: '/portfolio/:slug',
    name: 'project',
    component: project,
    props: true,
    meta: {
      matomoUrlCallback: (route) => `/portfolio/${route.params.slug}`,
    },
  },
  { path: '/new-project/', name: NEW_PROJECT_PAGE, component: newProject, meta: { matomoUrl: '/portfolio/' } },
  {
    path: '/edit-project/:slug',
    name: 'edit-project',
    component: editProject,
    props: true,
    meta: {
      matomoUrlCallback: (route) => `/portfolio/${route.params.slug}`,
    },
  },
];

const routes = flavorValues.showPortfolio ? portfolioRoutes : [];

export default routes;
