<template>
  <button
    :class="['button', 'button-with-icon-and-text', { 'button-with-icon-and-text--large': large }]"
    :disabled="disabled"
    @click="click"
  >
    <component
      class="button-with-icon-and-text__icon"
      :is="icon"
    />
    <span class="button-with-icon-and-text__text">{{ text }}</span>
  </button>
</template>

<script setup lang="ts">
interface Props {
  icon: string;
  text: string;
  large: boolean;
  disabled: boolean;
}

withDefaults(defineProps<Props>(), {
  icon: '',
  text: '',
  large: false,
  disabled: false,
});

const emit = defineEmits(['click']);

const click = () => {
  emit('click');
};
</script>

<script lang="ts">
import formElementIcons from '@/components/ui/form-element-icons';

export default {
  components: {
    ...formElementIcons,
  },
};
</script>

<style scoped lang="scss">
@import 'styles/helpers';

.button-with-icon-and-text {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  width: 100%;
  padding: 1px;

  &:disabled {
    background-color: transparent;
    opacity: 0.5;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: $small-spacing;
  }

  &__text {
    @include small-text;
  }

  $p: &;

  &--large {
    #{$p}__text {
      @include large-link;
    }
  }
}
</style>
